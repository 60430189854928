import {
  Alert,
  Dialog,
  DialogContent,
  DialogHeader,
  Heading,
} from '@hygraph/baukasten';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import { CloneProjectForm } from './CloneProjectForm';
import { trans } from 'i18n';
import { useClonePublicProject } from 'modules/projects/hooks/useClonePublicProject';

import { useEffect } from 'react';
import { Form } from 'react-final-form';
import {
  Events,
  rudderstackTrack,
} from 'modules/tracking/hooks/useTrackRudderstack';
import { CloneProjectMutationVariables } from 'modules/projects/gql/generated/cloneProjectMutation';
import { validateWithYup } from 'utils';
import { projectSchemaValidation } from 'modules/projects/components/CreateProject/CreateProjectDialog';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { useGetRegions } from 'modules/projects/components/CreateProject/useGetRegions';

interface Props {
  publicCloneAccessId: string;
}

export const ClonePublicProjectDialog = (props: Props) => {
  const { publicCloneAccessId } = props;
  const { regions, loading } = useGetRegions();
  const [clonePublicProjectMutation, clonePublicProjectMutationState] =
    useClonePublicProject();
  const navigate = useNavigate();
  const { search } = useLocation();
  useEffect(() => {
    rudderstackTrack(Events.CREATE_PROJECT, {
      type: 'clone public project',
      public_access_id: publicCloneAccessId,
    });
  }, [publicCloneAccessId]);

  if (loading || !regions) return null;

  const sourceProjectName = decodeURIComponent(
    (search.split('?name=')[1] || '').split('&')[0]
  );

  return (
    <Dialog isOpen aria-labelledby="clone-project-header" maxWidth="800px">
      <DialogHeader>
        <Heading as="h4" fontWeight="medium" id="clone-project-header">
          {/* TRANS component refactor */}
          {trans('Clone')} <b>{sourceProjectName}</b> {trans('project')}
        </Heading>
      </DialogHeader>
      <DialogContent>
        <Form<CloneProjectMutationVariables>
          keepDirtyOnReinitialize
          validate={validateWithYup(projectSchemaValidation)}
          initialValues={{
            name: trans('Clone of {{name}}', { name: sourceProjectName }),
            description: '',
            region: regions[0].id,
          }}
          subscription={{}}
          onSubmit={values => {
            rudderstackTrack(Events.PROJECT_CREATING, {
              public_access_id: publicCloneAccessId,
              type: 'public project cloning',
            });
            clonePublicProjectMutation({
              data: {
                publicCloneAccessId,
                name: values.name,
                description: values.description,
                region: values.region,
              },
            }).then(result => {
              if (result?.project?.id) {
                navigate(
                  `/pending/${result.project.id}?region=${values.region}`
                );
                rudderstackTrack(Events.PROJECT_CREATED, {
                  public_access_id: publicCloneAccessId,
                  type: 'public project cloned',
                  project_id: result.project.id,
                });
              }
            });
          }}
        >
          {({ handleSubmit }) => {
            return (
              <>
                <ApolloInlineError
                  error={clonePublicProjectMutationState.error}
                >
                  {(err, key) => (
                    <Alert mb="m" key={key}>
                      {err.message || err}
                    </Alert>
                  )}
                </ApolloInlineError>
                <CloneProjectForm
                  handleSubmit={handleSubmit}
                  submitting={clonePublicProjectMutationState.loading}
                  regions={regions}
                  hideCloneOptions
                />
              </>
            );
          }}
        </Form>
      </DialogContent>
    </Dialog>
  );
};
