import { CommonFilestack } from 'generated/graphql';
import { AssetConfigFragment } from 'modules/assets/gql/generated/AssetConfigFragment';
import _omit from 'lodash/omit';

/**
 * Assets examples
 *
 * Asset no security
 * https://media.graphassets.com/xGJ8MbKLTMKPKTRIsK4a
 *
 * Asset security
 * https://media.graphassets.com/security=policy:eyJleHBpcnkiOjE2MDgyMDIwOTgsImNhbGwiOlsicmVhZCIsImNvbnZlcnQiLCJleGlmIl19,signature:fa916b823309a98f2a9ccccc33b7e8615d281a05cba02bb8b9aacd813fd47813/xGJ8MbKLTMKPKTRIsK4a
 * https://media.graphassets.com/untos9xISjenmzyQoq6y?policy=eyJleHBpcnkiOjE2MTAwNTQ0NzE4MzksImNhbGwiOlsicmVhZCJdLCJoYW5kbGUiOiJ1bnRvczl4SVNqZW5tenlRb3E2eSJ9&signature=c5e1932efa9a67088f0496d648c3ce6a1c37473f31b26b98176ae05d2fd4f858
 *
 */
type FormatAssetUrlPropsURL = FormatAssetUrlPropsBase & {
  url: string;
  handle?: string;
};
type FormatAssetUrlPropsHandle = FormatAssetUrlPropsBase & {
  url?: string;
  handle: string;
};
type FormatAssetUrlPropsBase = {
  resize?: Resize;
  fit?: Fit;
  format?: string;
  assetConfig?: AssetConfigFragment | CommonFilestack; // TODO: fix type & null check
  noFormatting?: boolean;
};
type FormatAssetUrlProps = FormatAssetUrlPropsURL | FormatAssetUrlPropsHandle;

const ASSETS_URL_BASE = 'https://media.graphassets.com';

export enum Fit {
  crop = 'crop',
  max = 'max',
}

export interface Resize {
  width?: number;
  height?: number;
}

export const formatAssetUrl = (props: FormatAssetUrlProps) => {
  const { url, handle, resize, fit, format, assetConfig, noFormatting } = props;

  const urlFormat = noFormatting
    ? ''
    : format !== undefined
    ? format
    : '/output=format:jpg';

  const urlResize =
    resize?.width || resize?.height
      ? `/resize=${concatParams({ ...resize, fit })}`
      : '';

  let urlParsed = '';

  // INFO: When uploading an image w/o publishing we don't have the url from the BE so we have to construc the url w/ the handle
  if (!url) {
    urlParsed = `${ASSETS_URL_BASE}${urlFormat}${urlResize}/${handle}`;
  } else {
    const indexOfHandleUrl = url.lastIndexOf('/');
    const urlBase = url
      .substring(0, indexOfHandleUrl)
      // remove existing `/resize` part
      .replace(/\/(resize).*(height\:\d+)/, '');
    const urlHandle = url.substring(indexOfHandleUrl);

    urlParsed = `${urlBase}${urlFormat}${urlResize}${urlHandle}`;
  }

  const finalUrl = getAssetSecurityUrl({ url: urlParsed, assetConfig });
  return finalUrl;
};

function getAssetSecurityUrl({
  url,
  assetConfig,
}: Pick<FormatAssetUrlPropsURL, 'url' | 'assetConfig'>) {
  if (assetConfig?.security.enabled && !url.includes('security=')) {
    const urlSecurity = `/security=${concatParams(
      assetConfig?.security.auth ?? {}
    )}`;
    return url.replace(ASSETS_URL_BASE, `${ASSETS_URL_BASE}${urlSecurity}`);
  }
  return url;
}

function concatParams(obj?: Record<string, any>) {
  return Object.entries(obj || {})
    .filter(entry => entry[1] != null)
    .map(entry => entry.join(':'))
    .join(',');
}

export function getAssetClientConfig(assetConfig: Record<string, any>) {
  const isAssetSecurity = assetConfig.security.enabled;

  const assetSecurityValues = isAssetSecurity
    ? _omit(assetConfig.security.auth, ['__typename'])
    : {};
  // INFO: if security is enabled, clientOptions should contain the security policy & signature values
  const clientOptions = isAssetSecurity
    ? { security: { ...assetSecurityValues } }
    : {};

  const assetProperties = {
    size: true,
    mimetype: true,
    filename: true,
    width: true,
    height: true,
  };
  // INFO: if security is enabled, clientMetadata should contain the security policy & signature query params
  const clientMetadata = isAssetSecurity
    ? { ...assetProperties, ...assetSecurityValues }
    : assetProperties;

  return { clientOptions, clientMetadata };
}

export function fetchFileMetadata(
  handle: string,
  queryParams: Record<string, boolean | string>
) {
  const queryParamsString = Object.entries(queryParams).reduce(
    (acc, [key, value], index) => {
      if (index !== 0) acc += '&';
      acc += `${key}=${value}`;
      return acc;
    },
    ''
  );

  const url = `https://www.filestackapi.com/api/file/${handle}/metadata?${queryParamsString}`;

  return fetch(url).then(response => response.json());
}
