import { ProjectBasicFragment } from './../modules/projects/gql/generated/ProjectBasicFragment';

import { REGIONAL_APPS_HOSTNAME } from 'env';

export const useGetStudioOrClassicURL = () => {
  return {
    /**
      Goals of this function:
      
      - If `project.opensInClassic` is `true` (which should only happen for Enterprise customers), use classic

      - Otherwise use studio  
    */
    getUrl(project: ProjectBasicFragment, environmentName?: string) {
      const appName = project.opensInClassic ? 'classic' : 'studio';
      return regionalProjectUrl(project, appName, environmentName);
    },
  };
};

function regionalProjectUrl(
  { id, region: { id: regionId } }: ProjectBasicFragment,
  mode: 'studio' | 'classic',
  environmentName?: string
) {
  const { protocol, port } = location;
  const regionSubdomain = regionId.toLocaleLowerCase();
  let hostname = REGIONAL_APPS_HOSTNAME!.replace('{region}', regionSubdomain);
  if (mode === 'studio') {
    hostname = hostname.replace('app', 'studio');
  }
  return `${protocol}//${hostname}${port ? `:${port}` : ''}/${id}/${
    environmentName ?? 'master'
  }`;
}
