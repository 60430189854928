import * as React from 'react';
import { UseUserContext } from 'hooks/useUser';
import { ProjectListView } from 'components/ProjectList/ProjectListView';
import { Progress } from '@hygraph/baukasten';
import { useProjects } from 'modules/projects/hooks';
import { UseLaunchDarklyProvider } from 'modules/launchDarkly/useLaunchDarkly';
// import { useProjectChangedSubscription } from 'modules/projects/hooks/useProjectChangedSubscription';
import { useInviteChangedSubscription } from 'modules/projects/hooks/useInviteChangedSubscription';
import { trans } from 'i18n';
import { useAuthClient } from '@hygraph/client-auth';
import { useProjectUpdateSubscriptionQuery } from 'modules/projects/gql/generated/projectUpdateSubscriptionQuery';
import { useManagementApiClient } from 'modules/managementApi';
import { ProjectStatus } from 'generated/graphql';

const ProjectList: React.FC = () => {
  const { loading, error, projectListData } = useProjects();
  const { logout } = useAuthClient();
  // useProjectChangedSubscription(); // INFO: Opening a subscription to project changes is needed to have projects and pendingProjects updating via cache
  useInviteChangedSubscription(); // INFO: Opening a subscription to invite changes is needed to have pendingInvites updating via cache

  const client = useManagementApiClient();

  useProjectUpdateSubscriptionQuery({
    client,
    onSubscriptionData({ subscriptionData, client }) {
      const project = subscriptionData.data?.projectUpdate;
      if (!project) return;

      const id = client.cache.identify({
        __ref: `Project:${project.projectId}`,
      });

      if (project.status === ProjectStatus.DELETED) {
        return client.cache.modify({
          id,
          fields(_, { DELETE }) {
            return DELETE;
          },
        });
      }

      client.cache.modify({
        id,
        fields: {
          status() {
            return project.status;
          },
        },
      });
    },
  });

  if (error) {
    throw error;
  }
  if (loading) {
    return <Progress variant="slim" />;
  }
  if (!projectListData) {
    throw new Error(trans('Error while loading project data'));
  }

  const { projects, user, pendingInvites, pendingProjects, templates } =
    projectListData;

  // INFO: _orderBy(templates, ['__typename', 'name'], ['asc', 'asc']) didn't work
  const basicTemplates = templates
    .filter(template => template.__typename === 'Template')
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const starterTemplates = templates
    .filter(template => template.__typename === 'StarterTemplate')
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const sortedTemplates = [...starterTemplates, ...basicTemplates];

  return (
    <UseUserContext.Provider value={user}>
      <UseLaunchDarklyProvider>
        <ProjectListView
          projects={projects}
          pendingInvites={pendingInvites}
          pendingProjects={pendingProjects}
          templates={sortedTemplates}
          user={user}
          logout={logout}
        />
      </UseLaunchDarklyProvider>
    </UseUserContext.Provider>
  );
};

export default ProjectList;
