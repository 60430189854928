import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
export type ProjectBasicFragment = { __typename: 'Project', id: string, name: string, description?: string | null | undefined, picture?: string | null | undefined, status: Types.ProjectStatus, opensInClassic: boolean, meta: any, region: { __typename: 'Region', id: string, name: string } };

export const ProjectBasicFragment = gql`
    fragment ProjectBasicFragment on Project {
  id
  name
  description
  picture
  region {
    id
    name
  }
  status
  opensInClassic
  meta @client
}
    `;