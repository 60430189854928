import {
  project as projectValidation,
  object as yupObject,
  string as yupString,
} from '@graphcms/validation';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Heading,
  Inline,
} from '@hygraph/baukasten';
import { useAuthClient } from '@hygraph/client-auth';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import { WithModalProps } from 'components/Modal/ModalContext';
import { FORM_ERROR } from 'final-form';
import { ProjectStatus } from 'generated/graphql';
import { trans } from 'i18n';
import { useManagementApiClient } from 'modules/managementApi';
import { CreateProjectTemplateFields } from 'modules/projects/components/CreateProject/CreateProjectTemplateFields';
import { ProjectBasicFragment } from 'modules/projects/gql/generated/ProjectBasicFragment';
import { useProjectUpdateSubscriptionQuery } from 'modules/projects/gql/generated/projectUpdateSubscriptionQuery';
import { regionalSubscriptionsUrl } from 'modules/projects/helpers/regionalProjectUrl';
import { useCreateProject } from 'modules/projects/hooks';
import {
  Events,
  rudderstackTrack,
} from 'modules/tracking/hooks/useTrackRudderstack';
import { useMemo } from 'react';
import { Form, useFormState } from 'react-final-form';
import { TrackEvent, tracker, validateWithYup } from 'utils';
import { createClient } from '../../../managementApi/createClient';
import { useProjectCreatedSubscriptionQuery } from '../../gql/generated/projectCreatedSubscriptionQuery';

import { useGetStudioOrClassicURL } from 'hooks/useGetStudioOrClassicURL';

interface ProjectCreateValues {
  name: string;
  description?: string;
  region: string;
}

export const projectSchemaValidation = yupObject().shape({
  name: projectValidation.name.required(),
  description: projectValidation.description.nullable(),
  region: yupString().required(trans('Region is a required field')),
});

function WithRegion() {
  const { token, logout } = useAuthClient();
  const {
    values: { region },
  } = useFormState<ProjectCreateValues>();

  const { getUrl } = useGetStudioOrClassicURL();

  const regionalClient = useMemo(
    () =>
      createClient({
        token,
        onAuthError: logout,
        apiUrl: '', // Can be empty since this is a disposable, subscription-only client
        subscriptionsUrl: regionalSubscriptionsUrl(region),
      }),
    [token, logout, region]
  );

  useProjectCreatedSubscriptionQuery({
    client: regionalClient,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data) return;

      window.location.href = getUrl({
        id: data.projectCreated.id,
        region: {
          id: region,
        },
      } as ProjectBasicFragment);
    },
  });

  return null;
}

/**
 * Starts a subscription to the appropriate Content Mgmt server
 * and redirects on successful project creation
 * @constructor
 */
function ProjectCreationSubscriber() {
  const {
    values: { region },
  } = useFormState<ProjectCreateValues>();

  if (region.length) {
    return <WithRegion />;
  }

  return null;
}

export function CreateProjectDialog(props: WithModalProps) {
  const { onRequestClose } = props;

  const [createProject] = useCreateProject();
  const client = useManagementApiClient();

  useProjectUpdateSubscriptionQuery({
    client,
    onSubscriptionData: ({ subscriptionData, client }) => {
      const project = subscriptionData.data?.projectUpdate;
      if (!project) return;

      client.cache.modify({
        id: client.cache.identify({
          __ref: `Project:${project.projectId}`,
        }),
        fields: {
          status() {
            return project.status;
          },
        },
      });
    },
  });

  const handleCreateProject = async (values: ProjectCreateValues) => {
    try {
      const data = await createProject(values);
      if (!data?.createProject) return;

      const { id, name, description } = data.createProject.project;

      tracker.trackEvent(TrackEvent.PROJECT_CREATED, {
        projectId: id,
        projectName: name,
        projectDescription: description,
        region: values.region,
      });

      rudderstackTrack(Events.PROJECT_CREATED, {
        type: 'empty project created',
        project_id: id,
      });

      await new Promise((resolve, reject) => {
        const intervalId = setInterval(() => {
          const projectInCache =
            client.cache.readFragment<ProjectBasicFragment>({
              fragment: ProjectBasicFragment,
              fragmentName: 'ProjectBasicFragment',
              id: client.cache.identify(data.createProject.project),
            });

          if (!projectInCache) return;

          if (projectInCache.status === ProjectStatus.CREATION_FAILED) {
            reject(trans('Project creation failed, please try again.'));
            clearInterval(intervalId);
          }
        }, 100);
      });
    } catch (e) {
      return { [FORM_ERROR]: e };
    }
  };

  return (
    <Dialog isOpen onDismiss={onRequestClose} aria-labelledby="create-project">
      <Form<ProjectCreateValues>
        subscription={{
          submitting: true,
          submitError: true,
        }}
        validate={validateWithYup(projectSchemaValidation)}
        initialValues={{
          name: trans('Blank project'),
          description: '',
          region: '',
        }}
        onSubmit={handleCreateProject}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <Box as="form" onSubmit={handleSubmit}>
            <ProjectCreationSubscriber />
            {submitError && (
              <ApolloInlineError error={submitError}>
                {(err, key) => (
                  <Alert variantColor="error" key={key}>
                    {err.message || err}
                  </Alert>
                )}
              </ApolloInlineError>
            )}
            <DialogHeader>
              <Heading as="h4" fontWeight="medium">
                {trans('New project')}
              </Heading>
            </DialogHeader>
            <DialogContent>
              <CreateProjectTemplateFields />
            </DialogContent>
            <DialogFooter>
              <Inline justifyContent="flex-end">
                <Button
                  type="button"
                  size="large"
                  disabled={submitting}
                  onClick={onRequestClose}
                  variant="ghost"
                >
                  {trans('Cancel')}
                </Button>
                <Button
                  type="submit"
                  role="submit"
                  disabled={submitting}
                  size="large"
                  loading={submitting}
                  onClick={() => {
                    rudderstackTrack(Events.PROJECT_CREATING, {
                      type: 'empty project creating',
                    });
                  }}
                  loadingText={trans('Adding')}
                  data-test="CreateProjectButton"
                >
                  {trans('Add project')}
                </Button>
              </Inline>
            </DialogFooter>
          </Box>
        )}
      </Form>
    </Dialog>
  );
}
