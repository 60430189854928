import { ApolloError } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  Dialog,
  Flex,
  Heading,
  Image,
  StackOld,
  Text,
} from '@hygraph/baukasten';
import { ExternalLink as ExternalLinkIcon } from '@hygraph/icons';
import { project as projectValidation } from '@graphcms/validation';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import { WithModalProps } from 'components/Modal/ModalContext';
import { CreateProjectTemplateFields } from 'modules/projects/components/CreateProject/CreateProjectTemplateFields';
import { FORM_ERROR } from 'final-form';
import type { StarterTemplate, Template } from 'generated/graphql';
import { trans } from 'i18n';
import _omit from 'lodash/omit';
import { CardOverlap } from './CardOverlap';
import { generateMarkdownPreview } from 'modules/markdown/generateMarkdownPreview';
import { useCreateProject } from 'modules/projects/hooks';
import {
  rudderstackTrack,
  Events,
} from 'modules/tracking/hooks/useTrackRudderstack';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom-v5-compat';
import { tracker, TrackEvent, validateWithYup } from 'utils';
import {
  boolean as yupBoolean,
  object as yupObject,
  string as yupString,
} from '@graphcms/validation';

interface TemplateCreateValues {
  name: string;
  description?: string;
  region: string;
  includeTemplateContent: boolean;
}

const projectSchema = yupObject().shape({
  name: projectValidation.name.required(),
  description: projectValidation.description,
  region: yupString().required(trans('Region is a required field')),
  includeTemplateContent: yupBoolean(),
  includeContent: yupBoolean(),
});

export interface CreateTemplateDialogProps {
  template: Template | StarterTemplate;
  preloadCoverPicture: HTMLImageElement | null;
}

const markdownStyles = {
  strong: {
    display: 'block',
    marginTop: 'm',
    marginBottom: 'm',
    color: 'black',
    fontSize: '18px',
    fontWeight: 700,
  },
  'ul li': {
    marginLeft: '18px',
  },
};

export const CreateTemplateDialog = (
  props: WithModalProps<CreateTemplateDialogProps>
) => {
  const { template, preloadCoverPicture, onRequestClose } = props;

  const [createProject] = useCreateProject();
  const navigate = useNavigate();

  return (
    <Dialog
      isOpen
      onDismiss={onRequestClose}
      maxWidth="996"
      aria-labelledby="create-project"
    >
      <Form<TemplateCreateValues>
        subscription={{
          submitting: true,
          submitError: true,
        }}
        validate={validateWithYup(projectSchema)}
        initialValues={{
          name: template.name,
          description: template?.description ?? '',
          includeTemplateContent: template.id ? true : undefined,
          region: '',
        }}
        onSubmit={handleCreateTemplate}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <Box as="form" onSubmit={handleSubmit}>
            {submitError && (
              <ApolloInlineError error={submitError}>
                {(err, key) => (
                  <Alert variantColor="error" key={key}>
                    {err.message || err}
                  </Alert>
                )}
              </ApolloInlineError>
            )}

            {preloadCoverPicture && (
              <Image
                role="presentation"
                display="block"
                height="320"
                width="100%"
                sx={{ objectFit: 'cover', objectPosition: 'top center' }}
                src={preloadCoverPicture.currentSrc}
              />
            )}
            <Flex
              flexDirection="row"
              color="neutral.500"
              margin="layoutML"
              justifyContent="space-between"
            >
              <StackOld space="l" width="calc(100% - 350px)">
                <Box>
                  <Heading as="h4" fontWeight="bold" textTransform="capitalize">
                    {trans('Project details')}
                  </Heading>
                </Box>
                <CreateProjectTemplateFields template={template} />
                {Boolean(template.details) && (
                  <Box
                    width="100%"
                    fontSize="copy"
                    sx={markdownStyles}
                    dangerouslySetInnerHTML={{
                      __html: generateMarkdownPreview(String(template.details)),
                    }}
                  />
                )}
              </StackOld>
              <CardOverlap
                overlap={
                  <Button
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                    width="248"
                    size="large"
                    onClick={() =>
                      rudderstackTrack(Events.CREATE_PROJECT, {
                        type: 'create template project',
                        template_id: template.id,
                      })
                    }
                    loadingText={trans('Adding')}
                    data-test="CreateTemplateButton"
                  >
                    {trans('Add project')}
                  </Button>
                }
              >
                <StackOld width="256" space="m" fontSize="tiny">
                  {template.resources.length > 0 && (
                    <StackOld space="s">
                      <Text
                        textTransform="uppercase"
                        letterSpacing="1.2px"
                        fontWeight={500}
                      >
                        {trans('Resources')}
                      </Text>
                      <StackOld
                        space="s"
                        display="grid"
                        justifyItems="flex-start"
                      >
                        {template.resources.map(resource => (
                          <Button<PropsOf<'a'>>
                            key={resource.title}
                            as="a"
                            variant="link"
                            href={resource.url}
                            target="_blank"
                            iconAfter={ExternalLinkIcon}
                          >
                            <Text fontSize="interface">{resource.title}</Text>
                          </Button>
                        ))}
                      </StackOld>
                    </StackOld>
                  )}

                  {'stack' in template && (
                    <StackOld space="s">
                      <Text
                        textTransform="uppercase"
                        letterSpacing="1.2px"
                        fontWeight={500}
                      >
                        {trans('Stack')}
                      </Text>
                      <StackOld space="s" isInline shouldWrapChildren>
                        {template.stack.map(stack => (
                          <Image
                            key={stack.image}
                            src={stack.image}
                            alt={stack.title}
                          />
                        ))}
                      </StackOld>
                    </StackOld>
                  )}
                </StackOld>
              </CardOverlap>
            </Flex>
          </Box>
        )}
      </Form>
    </Dialog>
  );

  function handleCreateTemplate(values: TemplateCreateValues) {
    const variables = {
      ..._omit(values, 'includeTemplateContent'),
      description: values.description === '' ? null : values.description,
      template: template.id
        ? {
            templateId: template.id,
            content: values.includeTemplateContent,
          }
        : undefined,
    };

    rudderstackTrack(Events.PROJECT_CREATING, {
      type: 'template project creating',
      template_id: template.id,
    });

    return createProject(variables).then(
      data => {
        if (data?.createProject) {
          const {
            id: projectId,
            name,
            description,
          } = data.createProject.project;

          tracker.trackEvent(TrackEvent.PROJECT_CREATED, {
            projectId,
            projectName: name,
            projectDescription: description,
            region: values.region,
          });

          rudderstackTrack(Events.PROJECT_CREATED, {
            type: 'template project created',
            project_id: projectId,
            template_id: template.id,
          });

          const redirectUrl = template.id
            ? `/pending/${projectId}?region=${values.region}`
            : `/${projectId}/master`;
          navigate(redirectUrl);
        }
      },
      (error: ApolloError) => {
        return { [FORM_ERROR]: error };
      }
    );
  }
};
