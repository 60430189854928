import { TEST_MODE } from 'env';
import {
  ProviderConfig,
  useLDClient,
  withLDProvider as originalWithLDProvider,
} from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from './useFeatureFlag';
import { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from 'modules/user/hooks/useUser';
import { UserFragment } from 'modules/user/gql/generated/UserFragment';

export function withLDProvider(config: ProviderConfig) {
  if (typeof TEST_MODE === 'string') {
    return Component => Component;
  }
  return originalWithLDProvider(config);
}

interface UseLaunchDarklyContextType {
  success: boolean | undefined;
  LDClient: ReturnType<typeof useLDClient>;
}

const UseLaunchDarklyContext = createContext<UseLaunchDarklyContextType>({
  success: undefined,
  LDClient: undefined,
});

const LaunchDarklyProvider = ({
  user,
  ...rest
}: {
  user?: UserFragment;
  children: JSX.Element;
}) => {
  const LDClient = useLDClient();

  const initialUser = LDClient?.getContext();
  const initialSuccess = initialUser?.email ? true : undefined; // INFO: this is to ensure we call LDClient?.identify only once as the LDUser could be already identified

  const [success, setSuccess] =
    useState<UseLaunchDarklyContextType['success']>(initialSuccess);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (success === undefined && !loading) {
      setLoading(true);
      LDClient?.identify({
        key: 'user',
        name: user?.profile.name ?? undefined,
        email: user?.profile.email,
      })
        .then(() => {
          const identifiedUser = LDClient?.getContext(); // INFO: it has to be called right after it's resolved to get the identified user data
          if (identifiedUser?.email) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        })
        .catch(() => {
          setSuccess(false);
        })
        .finally(() => setLoading(false));
    }
  }, [LDClient, success, loading, user]);

  // INFO: Don't block the UI while LaunchDarkly is loading as we won't call LD if identify it's not successful
  if (success === false) {
    console.error('Failed to load Launchdarkly user.');
  }

  return (
    <UseLaunchDarklyContext.Provider value={{ success, LDClient }} {...rest} />
  );
};

export const UseLaunchDarklyProvider = ({
  ...rest
}: {
  children: JSX.Element;
}) => {
  const { user } = useUser();

  if (typeof TEST_MODE === 'string') {
    const LDClient = {
      allFlags: function (): Partial<FeatureFlags> {
        const flags = window?.__FEATURE_FLAGS__ || {};
        const testFlags = Array.isArray(flags) ? flags[0] : flags;
        return {
          ...testFlags,
        };
      },
    } as unknown as ReturnType<typeof useLDClient>;
    return (
      <UseLaunchDarklyContext.Provider
        value={{ success: true, LDClient }}
        {...rest}
      />
    );
  }

  return <LaunchDarklyProvider user={user} {...rest} />;
};

export function useLaunchDarkly() {
  return useContext(UseLaunchDarklyContext);
}
