import { Box } from '@hygraph/baukasten';

import type { Region } from 'modules/projects/components/CreateProject/useGetRegions';

export function getRegionSelectItems(regions: Region[] | undefined) {
  return (
    regions?.map(region => ({
      label: region.label ?? region.id,
      value: region.id,
      Component: () => (region?.icon ? <Box as={region.icon} mr="xs" /> : null),
    })) ?? []
  );
}
